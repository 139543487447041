
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'new-institute',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: false,
      dialogImageUrl: '',
      url: '',
      employee_url: '',
      imageEntityLogoUrl: '',
      dialogVisible: false,
      alertText: false,
      employee: {
        employee_name: '',
        employee_father_name: '',
        employee_mother_name: '',
        role: '',
        designation: '',
        employee_dob: '',
        employee_nid: '',
        employee_passport: '',
        employee_driving_license: '',
        employee_mobile: '',
        employee_email: '',
        employee_gender: '',
        employee_religion: '',
        employee_present_address: '',
        employee_present_district: '',
        employee_permanent_address: '',
        employee_permanent_district: '',
        employee_img: '',
      },
      user: {
        password: '',
        password_confirmation: '',
        active_status: '1',
      },
      trainning_institute: {
        logo: '',
        entity_id: '' as any,
        tranche_id: '',
        entity_type: 'Training Institute',
        training_institute_type: '',
        short_name: '',
        institute_name: '',
        institute_description: '',
        registration_number: '',
        registration_date: '',
        registration_authority: '',
        address: '',
        postcode: '',
        sub_district: '',
        district: '',
        division: '',
        telephone: '',
        fax: '',
        email: '',
        web_url: '',
        number_of_training_room: '',
        training_capacity: '',
        total_trainer_male: '',
        total_trainer_female: '',
        lab_equipment: '',
        status_remarks: '',
        active_status: '',
      },

      instituteTypes: [],
      empDesignation: [],
      association: [],
      divisions: [],
      districts: [],
      tranches: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
    };
  },
  async created() {
    await this.getTrainningInstituteTypes();
    await this.getDivision();
    await this.getDistrictForEmp();
    await this.associationList();
    //await this.getEmployeeDesignation();
    await this.getTranches();
  },
  methods: {
    updatePhoto(e) {
      this.trainning_institute.logo = e.target.files[0];
      this.url = URL.createObjectURL(this.trainning_institute.logo);
    },
    employeePhoto(e) {
      this.employee.employee_img = e.target.files[0];
      this.employee_url = URL.createObjectURL(this.employee.employee_img);
    },

    async formSubmit() {
      let formData = new FormData();

      formData.set('institute_logo', this.trainning_institute.logo);
      formData.set('employee_img', this.employee.employee_img);

      for (var key in this.employee) {
        formData.set(key, this.employee[key]);
      }
      for (var key in this.user) {
        formData.set(key, this.user[key]);
      }
      for (var key in this.trainning_institute) {
        formData.set(key, this.trainning_institute[key]);
      }
      let user_id = VueCookieNext.getCookie('_seip_user');
      let user_role_id = VueCookieNext.getCookie('_seip_role_id');
      formData.set('user_id', user_id.id);
      formData.set('user_role_id', user_role_id);
      this.loading = true;
      await ApiService.post('institute/save', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              (this.url = ''),
                (this.employee_url = ''),
                (this.employee = {
                  employee_name: '',
                  employee_father_name: '',
                  employee_mother_name: '',
                  role: '',
                  designation: '',
                  employee_dob: '',
                  employee_nid: '',
                  employee_passport: '',
                  employee_driving_license: '',
                  employee_mobile: '',
                  employee_email: '',
                  employee_gender: '',
                  employee_religion: '',
                  employee_present_address: '',
                  employee_present_district: '',
                  employee_permanent_address: '',
                  employee_permanent_district: '',
                  employee_img: '',
                }),
                (this.user = {
                  password: '',
                  password_confirmation: '',
                  active_status: '1',
                }),
                (this.trainning_institute = {
                  logo: '',
                  entity_id: '',
                  tranche_id: '',
                  entity_type: 'Training Institute',
                  training_institute_type: '',
                  short_name: '',
                  institute_name: '',
                  institute_description: '',
                  registration_number: '',
                  registration_date: '',
                  registration_authority: '',
                  address: '',
                  postcode: '',
                  sub_district: '',
                  district: '',
                  division: '',
                  telephone: '',
                  fax: '',
                  email: '',
                  web_url: '',
                  number_of_training_room: '',
                  training_capacity: '',
                  total_trainer_male: '',
                  total_trainer_female: '',
                  lab_equipment: '',
                  status_remarks: '',
                  active_status: '',
                });
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async getTrainningInstituteTypes() {
      await ApiService.get('configurations/institute_type/list')
        .then((response) => {
          this.instituteTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmployeeDesignation() {
      await ApiService.get(
        'entity_type_role/role/allroles?entity_type_id=1011&entity_id=' +
          this.trainning_institute.entity_id
      )
        .then((response) => {
          this.empDesignation = response.data.data.role;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDivision() {
      await ApiService.get('configurations/geo_division/list')
        .then((response) => {
          this.divisions = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get(
        'configurations/geo_district/list?division=' +
          this.trainning_institute.division
      )
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?division=' +
          this.trainning_institute.division +
          '&district=' +
          this.trainning_institute.district
      )
        .then((response) => {
          this.subDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      web_url: Yup.string().required().label('Web Url'),
      postcode: Yup.string().required().max(4).label('Postcode'),
      registration_number: Yup.string().required().label('Registration Number'),
      registration_authority: Yup.string()
        .required()
        .label('Registration Authority'),
      telephone: Yup.number().required().min(11).label('Telephone'),
      trainning_institute_name: Yup.string().required().label('Name'),
      short_name: Yup.string().required().label('Short Name'),

      employeeEmail: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label('Name'),
      // designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label('Gender'),
      mobile: Yup.number().required().min(11).label('Mobile'),
      dob: Yup.string().required().label('Date of Birth'),

      // password: Yup.string()
      //   .required()
      //   .matches(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      //   )
      //   .label("Password"),
      // confirmPassword: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Password didn't match!")
      //   .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
